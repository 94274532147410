import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Layout } from '../../../../components/layout'
import { useEventReports } from '../../../../hooks/useEventReports'
import { useTags } from '../../../../hooks/useTags'
import { EventReports } from '../../../../components/event-reports'
import { Article } from '../../../../components/article'
import { EventReport as EventReportType } from '../../../../types/event-report'
import { Tag as TagType } from '../../../../types/tag'
import { SEO } from '../../../../components/seo'

const EventReportPage = ({ params }: any) => {
	const { eventReports } = useEventReports()
	const [eventReport, setEventReport] = useState<EventReportType | null>(null)
	const { tags } = useTags()
	const [tag, setTag] = useState<TagType | null>(null)

	useEffect(() => {
		const eventReport = eventReports.find(eventReport => eventReport.title === params._title)
		if (eventReport) {
			setEventReport(eventReport)
		}
	}, [params, eventReports])

	useEffect(() => {
		const tag = tags.find(tag => tag.title === params._tag)
		if (tag) {
			setTag(tag)
		}
	}, [params, tags])

	return (
		<Layout>
			<SEO
				title={`${params._title} > ${params._tag} | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description={''}
				keywords={['メキシコ料理', '渋谷' , 'ファインメキシカン', 'ルビア', 'sarasa']}
			/>
			<h1 className="h1">{params._title} {`>`} {params._tag} | RUBIA ルビア / DJ sarasa</h1>
			<div className="story">
				{eventReport && tag && (
					<>
						<Article type="eventReport" article={eventReport} tag={tag} />
						<EventReports />
					</>
				)}
			</div>
		</Layout>
	)
}

export default EventReportPage
